
export const packages = [
  "@openimis/fe-core@git+https://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:x-oauth-basic",
  "@openimis/fe-home@git+https://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:x-oauth-basic",
  "@openimis/fe-location@git+https://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:x-oauth-basic",
  "@openimis/fe-insuree@git+https://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:x-oauth-basic",
  "@openimis/fe-medical@git+https://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:x-oauth-basic",
  "@openimis/fe-medical_pricelist@git+https://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:x-oauth-basic",
  "@openimis/fe-product@git+https://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:x-oauth-basic",
  "@openimis/fe-policy@git+https://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:x-oauth-basic",
  "@openimis/fe-payer@git+https://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:x-oauth-basic",
  "@openimis/fe-contribution@git+https://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:x-oauth-basic",
  "@openimis/fe-payment@git+https://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:x-oauth-basic",
  "@openimis/fe-claim@git+https://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:x-oauth-basic",
  "@openimis/fe-claim_batch@git+https://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:x-oauth-basic",
  "@openimis/fe-admin@git+https://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:x-oauth-basic",
  "@openimis/fe-tools@git+https://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:x-oauth-basic",
  "@openimis/fe-profile@git+https://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:x-oauth-basic",
  "@openimis/fe-Language_nig@git+https://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:x-oauth-basic",
  "@openimis/fe-calculation@git+https://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:x-oauth-basic",
  "@openimis/fe-policyholder@git+https://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:x-oauth-basic",
  "@openimis/fe-contribution_plan@git+https://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:x-oauth-basic",
  "@openimis/fe-contract@git+https://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:x-oauth-basic",
  "@openimis/fe-invoice@git+https://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:x-oauth-basic",
  "@openimis/fe-preauthorization@git+https://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:x-oauth-basic",
  "@openimis/fe-grievance@git+https://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:x-oauth-basic",
  "@openimis/fe-accreditation@git+https://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:x-oauth-basic"
];


export function loadModules (cfg = {}) {
  return [
    require("@openimis/fe-core").CoreModule(cfg["fe-core"] || {}),
    require("@openimis/fe-home").HomeModule(cfg["fe-home"] || {}),
    require("@openimis/fe-location").LocationModule(cfg["fe-location"] || {}),
    require("@openimis/fe-insuree").InsureeModule(cfg["fe-insuree"] || {}),
    require("@openimis/fe-medical").MedicalModule(cfg["fe-medical"] || {}),
    require("@openimis/fe-medical_pricelist").MedicalPriceListModule(cfg["fe-medical_pricelist"] || {}),
    require("@openimis/fe-product").ProductModule(cfg["fe-product"] || {}),
    require("@openimis/fe-policy").PolicyModule(cfg["fe-policy"] || {}),
    require("@openimis/fe-payer").PayerModule(cfg["fe-payer"] || {}),
    require("@openimis/fe-contribution").ContributionModule(cfg["fe-contribution"] || {}),
    require("@openimis/fe-payment").PaymentModule(cfg["fe-payment"] || {}),
    require("@openimis/fe-claim").ClaimModule(cfg["fe-claim"] || {}),
    require("@openimis/fe-claim_batch").ClaimBatchModule(cfg["fe-claim_batch"] || {}),
    require("@openimis/fe-admin").AdminModule(cfg["fe-admin"] || {}),
    require("@openimis/fe-tools").ToolsModule(cfg["fe-tools"] || {}),
    require("@openimis/fe-profile").ProfileModule(cfg["fe-profile"] || {}),
    require("@openimis/fe-Language_nig").LanguageEnGmbModule(cfg["fe-Language_nig"] || {}),
    require("@openimis/fe-calculation").CalculationModule(cfg["fe-calculation"] || {}),
    require("@openimis/fe-policyholder").PolicyHolderModule(cfg["fe-policyholder"] || {}),
    require("@openimis/fe-contribution_plan").ContributionPlanModule(cfg["fe-contribution_plan"] || {}),
    require("@openimis/fe-contract").ContractModule(cfg["fe-contract"] || {}),
    require("@openimis/fe-invoice").InvoiceModule(cfg["fe-invoice"] || {}),
    require("@openimis/fe-preauthorization").PreauthorizationModule(cfg["fe-preauthorization"] || {}),
    require("@openimis/fe-grievance").GrievanceModule(cfg["fe-grievance"] || {}),
    require("@openimis/fe-accreditation").AccreditationModule(cfg["fe-accreditation"] || {})
  ];

}
